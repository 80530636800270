/**
 * THIS FILE WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 * @codegen <<SignedSource::9ce982d5755939970cf54305cd8c2c29>>
 * @codegenCommand yarn build tokens
 */
export default `
html[data-color-mode="light"][data-theme~="light:dark"],
html[data-color-mode="dark"][data-theme~="dark:dark"] {
  color-scheme: dark;
  --ds-text: #BFC1C4;
  --ds-text-accent-lime: #B3DF72;
  --ds-text-accent-lime-bolder: #D3F1A7;
  --ds-text-accent-red: #FD9891;
  --ds-text-accent-red-bolder: #FFD5D2;
  --ds-text-accent-orange: #FBC828;
  --ds-text-accent-orange-bolder: #FCE4A6;
  --ds-text-accent-yellow: #EED12B;
  --ds-text-accent-yellow-bolder: #F5E989;
  --ds-text-accent-green: #7EE2B8;
  --ds-text-accent-green-bolder: #BAF3DB;
  --ds-text-accent-teal: #9DD9EE;
  --ds-text-accent-teal-bolder: #C6EDFB;
  --ds-text-accent-blue: #8FB8F6;
  --ds-text-accent-blue-bolder: #CFE1FD;
  --ds-text-accent-purple: #D8A0F7;
  --ds-text-accent-purple-bolder: #EED7FC;
  --ds-text-accent-magenta: #F797D2;
  --ds-text-accent-magenta-bolder: #FDD0EC;
  --ds-text-accent-gray: #A9ABAF;
  --ds-text-accent-gray-bolder: #E2E3E4;
  --ds-text-disabled: #E5E9F640;
  --ds-text-inverse: #1F1F21;
  --ds-text-selected: #669DF1;
  --ds-text-brand: #669DF1;
  --ds-text-danger: #FD9891;
  --ds-text-warning: #FBC828;
  --ds-text-warning-inverse: #1F1F21;
  --ds-text-success: #B3DF72;
  --ds-text-discovery: #D8A0F7;
  --ds-text-information: #8FB8F6;
  --ds-text-subtlest: #96999E;
  --ds-text-subtle: #A9ABAF;
  --ds-link: #669DF1;
  --ds-link-pressed: #8FB8F6;
  --ds-link-visited: #D8A0F7;
  --ds-link-visited-pressed: #EED7FC;
  --ds-icon: #CECFD2;
  --ds-icon-accent-lime: #82B536;
  --ds-icon-accent-red: #E2483D;
  --ds-icon-accent-orange: #F68909;
  --ds-icon-accent-yellow: #EED12B;
  --ds-icon-accent-green: #2ABB7F;
  --ds-icon-accent-teal: #42B2D7;
  --ds-icon-accent-blue: #4688EC;
  --ds-icon-accent-purple: #BF63F3;
  --ds-icon-accent-magenta: #DA62AC;
  --ds-icon-accent-gray: #7E8188;
  --ds-icon-disabled: #E5E9F640;
  --ds-icon-inverse: #1F1F21;
  --ds-icon-selected: #669DF1;
  --ds-icon-brand: #669DF1;
  --ds-icon-danger: #F15B50;
  --ds-icon-warning: #FBC828;
  --ds-icon-warning-inverse: #1F1F21;
  --ds-icon-success: #B3DF72;
  --ds-icon-discovery: #BF63F3;
  --ds-icon-information: #4688EC;
  --ds-icon-subtlest: #96999E;
  --ds-icon-subtle: #A9ABAF;
  --ds-border: #E3E4F21F;
  --ds-border-accent-lime: #82B536;
  --ds-border-accent-red: #F15B50;
  --ds-border-accent-orange: #F68909;
  --ds-border-accent-yellow: #CF9F02;
  --ds-border-accent-green: #2ABB7F;
  --ds-border-accent-teal: #42B2D7;
  --ds-border-accent-blue: #4688EC;
  --ds-border-accent-purple: #BF63F3;
  --ds-border-accent-magenta: #DA62AC;
  --ds-border-accent-gray: #7E8188;
  --ds-border-disabled: #CECED912;
  --ds-border-focused: #8FB8F6;
  --ds-border-input: #7E8188;
  --ds-border-inverse: #18191A;
  --ds-border-selected: #669DF1;
  --ds-border-brand: #669DF1;
  --ds-border-danger: #F15B50;
  --ds-border-warning: #F68909;
  --ds-border-success: #82B536;
  --ds-border-discovery: #BF63F3;
  --ds-border-information: #4688EC;
  --ds-border-bold: #7E8188;
  --ds-background-accent-lime-subtlest: #28311B;
  --ds-background-accent-lime-subtlest-hovered: #37471F;
  --ds-background-accent-lime-subtlest-pressed: #4C6B1F;
  --ds-background-accent-lime-subtler: #37471F;
  --ds-background-accent-lime-subtler-hovered: #4C6B1F;
  --ds-background-accent-lime-subtler-pressed: #5B7F24;
  --ds-background-accent-lime-subtle: #4C6B1F;
  --ds-background-accent-lime-subtle-hovered: #37471F;
  --ds-background-accent-lime-subtle-pressed: #28311B;
  --ds-background-accent-lime-bolder: #94C748;
  --ds-background-accent-lime-bolder-hovered: #B3DF72;
  --ds-background-accent-lime-bolder-pressed: #D3F1A7;
  --ds-background-accent-red-subtlest: #42221F;
  --ds-background-accent-red-subtlest-hovered: #5D1F1A;
  --ds-background-accent-red-subtlest-pressed: #AE2E24;
  --ds-background-accent-red-subtler: #5D1F1A;
  --ds-background-accent-red-subtler-hovered: #AE2E24;
  --ds-background-accent-red-subtler-pressed: #C9372C;
  --ds-background-accent-red-subtle: #AE2E24;
  --ds-background-accent-red-subtle-hovered: #5D1F1A;
  --ds-background-accent-red-subtle-pressed: #42221F;
  --ds-background-accent-red-bolder: #F87168;
  --ds-background-accent-red-bolder-hovered: #FD9891;
  --ds-background-accent-red-bolder-pressed: #FFD5D2;
  --ds-background-accent-orange-subtlest: #3A2C1F;
  --ds-background-accent-orange-subtlest-hovered: #693200;
  --ds-background-accent-orange-subtlest-pressed: #9E4C00;
  --ds-background-accent-orange-subtler: #693200;
  --ds-background-accent-orange-subtler-hovered: #9E4C00;
  --ds-background-accent-orange-subtler-pressed: #BD5B00;
  --ds-background-accent-orange-subtle: #9E4C00;
  --ds-background-accent-orange-subtle-hovered: #693200;
  --ds-background-accent-orange-subtle-pressed: #3A2C1F;
  --ds-background-accent-orange-bolder: #FCA700;
  --ds-background-accent-orange-bolder-hovered: #FBC828;
  --ds-background-accent-orange-bolder-pressed: #FCE4A6;
  --ds-background-accent-yellow-subtlest: #332E1B;
  --ds-background-accent-yellow-subtlest-hovered: #533F04;
  --ds-background-accent-yellow-subtlest-pressed: #7F5F01;
  --ds-background-accent-yellow-subtler: #533F04;
  --ds-background-accent-yellow-subtler-hovered: #7F5F01;
  --ds-background-accent-yellow-subtler-pressed: #946F00;
  --ds-background-accent-yellow-subtle: #7F5F01;
  --ds-background-accent-yellow-subtle-hovered: #533F04;
  --ds-background-accent-yellow-subtle-pressed: #332E1B;
  --ds-background-accent-yellow-bolder: #DDB30E;
  --ds-background-accent-yellow-bolder-hovered: #EED12B;
  --ds-background-accent-yellow-bolder-pressed: #F5E989;
  --ds-background-accent-green-subtlest: #1C3329;
  --ds-background-accent-green-subtlest-hovered: #164B35;
  --ds-background-accent-green-subtlest-pressed: #216E4E;
  --ds-background-accent-green-subtler: #164B35;
  --ds-background-accent-green-subtler-hovered: #216E4E;
  --ds-background-accent-green-subtler-pressed: #1F845A;
  --ds-background-accent-green-subtle: #216E4E;
  --ds-background-accent-green-subtle-hovered: #164B35;
  --ds-background-accent-green-subtle-pressed: #1C3329;
  --ds-background-accent-green-bolder: #4BCE97;
  --ds-background-accent-green-bolder-hovered: #7EE2B8;
  --ds-background-accent-green-bolder-pressed: #BAF3DB;
  --ds-background-accent-teal-subtlest: #1E3137;
  --ds-background-accent-teal-subtlest-hovered: #164555;
  --ds-background-accent-teal-subtlest-pressed: #206A83;
  --ds-background-accent-teal-subtler: #164555;
  --ds-background-accent-teal-subtler-hovered: #206A83;
  --ds-background-accent-teal-subtler-pressed: #227D9B;
  --ds-background-accent-teal-subtle: #206A83;
  --ds-background-accent-teal-subtle-hovered: #164555;
  --ds-background-accent-teal-subtle-pressed: #1E3137;
  --ds-background-accent-teal-bolder: #6CC3E0;
  --ds-background-accent-teal-bolder-hovered: #9DD9EE;
  --ds-background-accent-teal-bolder-pressed: #C6EDFB;
  --ds-background-accent-blue-subtlest: #1C2B42;
  --ds-background-accent-blue-subtlest-hovered: #123263;
  --ds-background-accent-blue-subtlest-pressed: #1558BC;
  --ds-background-accent-blue-subtler: #123263;
  --ds-background-accent-blue-subtler-hovered: #1558BC;
  --ds-background-accent-blue-subtler-pressed: #1868DB;
  --ds-background-accent-blue-subtle: #1558BC;
  --ds-background-accent-blue-subtle-hovered: #123263;
  --ds-background-accent-blue-subtle-pressed: #1C2B42;
  --ds-background-accent-blue-bolder: #669DF1;
  --ds-background-accent-blue-bolder-hovered: #8FB8F6;
  --ds-background-accent-blue-bolder-pressed: #CFE1FD;
  --ds-background-accent-purple-subtlest: #35243F;
  --ds-background-accent-purple-subtlest-hovered: #48245D;
  --ds-background-accent-purple-subtlest-pressed: #803FA5;
  --ds-background-accent-purple-subtler: #48245D;
  --ds-background-accent-purple-subtler-hovered: #803FA5;
  --ds-background-accent-purple-subtler-pressed: #964AC0;
  --ds-background-accent-purple-subtle: #803FA5;
  --ds-background-accent-purple-subtle-hovered: #48245D;
  --ds-background-accent-purple-subtle-pressed: #35243F;
  --ds-background-accent-purple-bolder: #C97CF4;
  --ds-background-accent-purple-bolder-hovered: #D8A0F7;
  --ds-background-accent-purple-bolder-pressed: #EED7FC;
  --ds-background-accent-magenta-subtlest: #3D2232;
  --ds-background-accent-magenta-subtlest-hovered: #50253F;
  --ds-background-accent-magenta-subtlest-pressed: #943D73;
  --ds-background-accent-magenta-subtler: #50253F;
  --ds-background-accent-magenta-subtler-hovered: #943D73;
  --ds-background-accent-magenta-subtler-pressed: #AE4787;
  --ds-background-accent-magenta-subtle: #943D73;
  --ds-background-accent-magenta-subtle-hovered: #50253F;
  --ds-background-accent-magenta-subtle-pressed: #3D2232;
  --ds-background-accent-magenta-bolder: #E774BB;
  --ds-background-accent-magenta-bolder-hovered: #F797D2;
  --ds-background-accent-magenta-bolder-pressed: #FDD0EC;
  --ds-background-accent-gray-subtlest: #303134;
  --ds-background-accent-gray-subtlest-hovered: #3D3F43;
  --ds-background-accent-gray-subtlest-pressed: #4B4D51;
  --ds-background-accent-gray-subtler: #4B4D51;
  --ds-background-accent-gray-subtler-hovered: #63666B;
  --ds-background-accent-gray-subtler-pressed: #7E8188;
  --ds-background-accent-gray-subtle: #63666B;
  --ds-background-accent-gray-subtle-hovered: #4B4D51;
  --ds-background-accent-gray-subtle-pressed: #3D3F43;
  --ds-background-accent-gray-bolder: #96999E;
  --ds-background-accent-gray-bolder-hovered: #A9ABAF;
  --ds-background-accent-gray-bolder-pressed: #BFC1C4;
  --ds-background-disabled: #BDBDBD0A;
  --ds-background-input: #242528;
  --ds-background-input-hovered: #2B2C2F;
  --ds-background-input-pressed: #242528;
  --ds-background-inverse-subtle: #FFFFFF29;
  --ds-background-inverse-subtle-hovered: #FFFFFF3D;
  --ds-background-inverse-subtle-pressed: #FFFFFF52;
  --ds-background-neutral: #CECED912;
  --ds-background-neutral-hovered: #E3E4F21F;
  --ds-background-neutral-pressed: #E5E9F640;
  --ds-background-neutral-subtle: #00000000;
  --ds-background-neutral-subtle-hovered: #CECED912;
  --ds-background-neutral-subtle-pressed: #E3E4F21F;
  --ds-background-neutral-bold: #CECFD2;
  --ds-background-neutral-bold-hovered: #BFC1C4;
  --ds-background-neutral-bold-pressed: #A9ABAF;
  --ds-background-selected: #1C2B42;
  --ds-background-selected-hovered: #123263;
  --ds-background-selected-pressed: #1558BC;
  --ds-background-selected-bold: #669DF1;
  --ds-background-selected-bold-hovered: #8FB8F6;
  --ds-background-selected-bold-pressed: #CFE1FD;
  --ds-background-brand-subtlest: #1C2B42;
  --ds-background-brand-subtlest-hovered: #123263;
  --ds-background-brand-subtlest-pressed: #1558BC;
  --ds-background-brand-bold: #669DF1;
  --ds-background-brand-bold-hovered: #8FB8F6;
  --ds-background-brand-bold-pressed: #CFE1FD;
  --ds-background-brand-boldest: #E9F2FE;
  --ds-background-brand-boldest-hovered: #CFE1FD;
  --ds-background-brand-boldest-pressed: #8FB8F6;
  --ds-background-danger: #42221F;
  --ds-background-danger-hovered: #5D1F1A;
  --ds-background-danger-pressed: #AE2E24;
  --ds-background-danger-bold: #F87168;
  --ds-background-danger-bold-hovered: #FD9891;
  --ds-background-danger-bold-pressed: #FFD5D2;
  --ds-background-warning: #3A2C1F;
  --ds-background-warning-hovered: #693200;
  --ds-background-warning-pressed: #9E4C00;
  --ds-background-warning-bold: #FBC828;
  --ds-background-warning-bold-hovered: #FCA700;
  --ds-background-warning-bold-pressed: #F68909;
  --ds-background-success: #28311B;
  --ds-background-success-hovered: #37471F;
  --ds-background-success-pressed: #4C6B1F;
  --ds-background-success-bold: #94C748;
  --ds-background-success-bold-hovered: #B3DF72;
  --ds-background-success-bold-pressed: #D3F1A7;
  --ds-background-discovery: #35243F;
  --ds-background-discovery-hovered: #48245D;
  --ds-background-discovery-pressed: #803FA5;
  --ds-background-discovery-bold: #C97CF4;
  --ds-background-discovery-bold-hovered: #D8A0F7;
  --ds-background-discovery-bold-pressed: #EED7FC;
  --ds-background-information: #1C2B42;
  --ds-background-information-hovered: #123263;
  --ds-background-information-pressed: #1558BC;
  --ds-background-information-bold: #669DF1;
  --ds-background-information-bold-hovered: #8FB8F6;
  --ds-background-information-bold-pressed: #CFE1FD;
  --ds-blanket: #10121499;
  --ds-blanket-selected: #1D7AFC14;
  --ds-blanket-danger: #E3493514;
  --ds-interaction-hovered: #ffffff33;
  --ds-interaction-pressed: #ffffff5c;
  --ds-skeleton: #CECED912;
  --ds-skeleton-subtle: #BDBDBD0A;
  --ds-chart-categorical-1: #2898BD;
  --ds-chart-categorical-1-hovered: #42B2D7;
  --ds-chart-categorical-2: #D8A0F7;
  --ds-chart-categorical-2-hovered: #EED7FC;
  --ds-chart-categorical-3: #E06C00;
  --ds-chart-categorical-3-hovered: #F68909;
  --ds-chart-categorical-4: #F797D2;
  --ds-chart-categorical-4-hovered: #FDD0EC;
  --ds-chart-categorical-5: #CFE1FD;
  --ds-chart-categorical-5-hovered: #E9F2FE;
  --ds-chart-categorical-6: #AF59E1;
  --ds-chart-categorical-6-hovered: #BF63F3;
  --ds-chart-categorical-7: #FDD0EC;
  --ds-chart-categorical-7-hovered: #FFECF8;
  --ds-chart-categorical-8: #FBC828;
  --ds-chart-categorical-8-hovered: #FCE4A6;
  --ds-chart-lime-bold: #82B536;
  --ds-chart-lime-bold-hovered: #94C748;
  --ds-chart-lime-bolder: #94C748;
  --ds-chart-lime-bolder-hovered: #B3DF72;
  --ds-chart-lime-boldest: #B3DF72;
  --ds-chart-lime-boldest-hovered: #D3F1A7;
  --ds-chart-neutral: #7E8188;
  --ds-chart-neutral-hovered: #96999E;
  --ds-chart-red-bold: #E2483D;
  --ds-chart-red-bold-hovered: #F15B50;
  --ds-chart-red-bolder: #F15B50;
  --ds-chart-red-bolder-hovered: #F87168;
  --ds-chart-red-boldest: #FD9891;
  --ds-chart-red-boldest-hovered: #FFD5D2;
  --ds-chart-orange-bold: #F68909;
  --ds-chart-orange-bold-hovered: #FCA700;
  --ds-chart-orange-bolder: #FCA700;
  --ds-chart-orange-bolder-hovered: #FBC828;
  --ds-chart-orange-boldest: #FBC828;
  --ds-chart-orange-boldest-hovered: #FCE4A6;
  --ds-chart-yellow-bold: #CF9F02;
  --ds-chart-yellow-bold-hovered: #DDB30E;
  --ds-chart-yellow-bolder: #DDB30E;
  --ds-chart-yellow-bolder-hovered: #EED12B;
  --ds-chart-yellow-boldest: #EED12B;
  --ds-chart-yellow-boldest-hovered: #F5E989;
  --ds-chart-green-bold: #2ABB7F;
  --ds-chart-green-bold-hovered: #4BCE97;
  --ds-chart-green-bolder: #4BCE97;
  --ds-chart-green-bolder-hovered: #7EE2B8;
  --ds-chart-green-boldest: #7EE2B8;
  --ds-chart-green-boldest-hovered: #BAF3DB;
  --ds-chart-teal-bold: #42B2D7;
  --ds-chart-teal-bold-hovered: #6CC3E0;
  --ds-chart-teal-bolder: #6CC3E0;
  --ds-chart-teal-bolder-hovered: #9DD9EE;
  --ds-chart-teal-boldest: #9DD9EE;
  --ds-chart-teal-boldest-hovered: #C6EDFB;
  --ds-chart-blue-bold: #357DE8;
  --ds-chart-blue-bold-hovered: #4688EC;
  --ds-chart-blue-bolder: #4688EC;
  --ds-chart-blue-bolder-hovered: #669DF1;
  --ds-chart-blue-boldest: #8FB8F6;
  --ds-chart-blue-boldest-hovered: #CFE1FD;
  --ds-chart-purple-bold: #AF59E1;
  --ds-chart-purple-bold-hovered: #BF63F3;
  --ds-chart-purple-bolder: #BF63F3;
  --ds-chart-purple-bolder-hovered: #C97CF4;
  --ds-chart-purple-boldest: #D8A0F7;
  --ds-chart-purple-boldest-hovered: #EED7FC;
  --ds-chart-magenta-bold: #CD519D;
  --ds-chart-magenta-bold-hovered: #DA62AC;
  --ds-chart-magenta-bolder: #DA62AC;
  --ds-chart-magenta-bolder-hovered: #E774BB;
  --ds-chart-magenta-boldest: #F797D2;
  --ds-chart-magenta-boldest-hovered: #FDD0EC;
  --ds-chart-gray-bold: #7E8188;
  --ds-chart-gray-bold-hovered: #96999E;
  --ds-chart-gray-bolder: #96999E;
  --ds-chart-gray-bolder-hovered: #A9ABAF;
  --ds-chart-gray-boldest: #A9ABAF;
  --ds-chart-gray-boldest-hovered: #BFC1C4;
  --ds-chart-brand: #4688EC;
  --ds-chart-brand-hovered: #669DF1;
  --ds-chart-danger: #E2483D;
  --ds-chart-danger-hovered: #F15B50;
  --ds-chart-danger-bold: #FD9891;
  --ds-chart-danger-bold-hovered: #FFD5D2;
  --ds-chart-warning: #FCA700;
  --ds-chart-warning-hovered: #FBC828;
  --ds-chart-warning-bold: #FCE4A6;
  --ds-chart-warning-bold-hovered: #FFF5DB;
  --ds-chart-success: #82B536;
  --ds-chart-success-hovered: #94C748;
  --ds-chart-success-bold: #B3DF72;
  --ds-chart-success-bold-hovered: #D3F1A7;
  --ds-chart-discovery: #AF59E1;
  --ds-chart-discovery-hovered: #BF63F3;
  --ds-chart-discovery-bold: #D8A0F7;
  --ds-chart-discovery-bold-hovered: #EED7FC;
  --ds-chart-information: #357DE8;
  --ds-chart-information-hovered: #4688EC;
  --ds-chart-information-bold: #8FB8F6;
  --ds-chart-information-bold-hovered: #CFE1FD;
  --ds-surface: #1F1F21;
  --ds-surface-hovered: #242528;
  --ds-surface-pressed: #2B2C2F;
  --ds-surface-overlay: #2B2C2F;
  --ds-surface-overlay-hovered: #303134;
  --ds-surface-overlay-pressed: #3D3F43;
  --ds-surface-raised: #242528;
  --ds-surface-raised-hovered: #2B2C2F;
  --ds-surface-raised-pressed: #303134;
  --ds-surface-sunken: #18191A;
  --ds-shadow-overflow: 0px 0px 12px #0104048F, 0px 0px 1px #01040480;
  --ds-shadow-overflow-perimeter: #01040480;
  --ds-shadow-overflow-spread: #0104048f;
  --ds-shadow-overlay: 0px 0px 0px 1px #BDBDBD1F, 0px 8px 12px #0104045C, 0px 0px 1px 1px #01040480;
  --ds-shadow-raised: 0px 0px 0px 1px #00000000, 0px 1px 1px #01040480, 0px 0px 1px #01040480;
  --ds-opacity-disabled: 0.4;
  --ds-opacity-loading: 0.2;
  --ds-UNSAFE-transparent: transparent;
  --ds-elevation-surface-current: #1F1F21;
}
`;
